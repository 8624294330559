<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.outbound_plan") }}
    </template>
    <template v-slot:contentBody="wrap">
      <a-row
        :gutter="[16, 16]"
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <a-col flex="1">
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-input
                style="width: 250px"
                :placeholder="
                  $t('warehouse.plan_number_or_out_warehouse_number')
                "
                v-model:value="plan"
                allow-clear
              ></a-input>
            </a-col>
            <a-col>
              <a-input
                style="width: 250px"
                v-model:value="box"
                :placeholder="`${$t('warehouse.box_no')}/${$t(
                  'common.user_defined_box_no'
                )}`"
                allowClear
              />
            </a-col>
            <a-col>
              <SearchProduct ref="refSearchProduct"></SearchProduct>
            </a-col>
            <a-col>
              <a-select
                v-model:value="warehouseId"
                allow-clear
                :placeholder="$t('warehouse.out_warehouse')"
                style="width: 250px"
                show-search
                optionFilterProp="search-key"
                :loading="outWarehouseLoading"
              >
                <a-select-option
                  v-for="(item, key) in dropdownList.outWarehouse"
                  :key="key"
                  :value="item.id"
                  :search-key="item.warehouseNo + item.warehouseName"
                  :title="item.warehouseNo + '(' + item.warehouseName + ')'"
                >
                  {{ item.warehouseNo + "(" + item.warehouseName + ")" }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="outWarehouseType"
                allow-clear
                :placeholder="$t('warehouse.out_warehouse_type')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in outWarehouseTypeEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('outWarehouseType', value))"
                >
                  {{ $t($enumLangkey("outWarehouseType", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="outWarehouseStatus"
                allow-clear
                :placeholder="$t('warehouse.plan_status')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in outPlanStatus"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('outPlanStatus', value))"
                >
                  {{ $t($enumLangkey("outPlanStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="transDeliverType"
                allow-clear
                :placeholder="$t('warehouse.transportation_delivery_way')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in transportationDeliveryWayEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('transportationDeliveryWay', value))"
                >
                  {{ $t($enumLangkey("transportationDeliveryWay", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <a-select
                v-model:value="transDeliverStatus"
                allow-clear
                :placeholder="$t('warehouse.transportation_delivery_status')"
                style="width: 250px"
              >
                <a-select-option
                  v-for="(value, key) in deliveryStatusEnum"
                  :key="key"
                  :value="value"
                  :title="$t($enumLangkey('deliveryStatus', value))"
                >
                  {{ $t($enumLangkey("deliveryStatus", value)) }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col>
              <span>{{ $t("logistics.create_date") }}: </span>
              <a-range-picker
                style="width: 250px"
                v-model:value="dataRange"
                class="mb-1"
              />
            </a-col>
            <a-col>
              <a-space>
                <a-button type="primary" @click="handleChangeStatus">{{
                  $t("common.query")
                }}</a-button>
                <a-button
                  type="ghost"
                  :loading="exportLoading"
                  @click="handleDownloadExcel"
                  >{{ $t("common.export") }}</a-button
                >
              </a-space>
            </a-col>
          </a-row>
        </a-col>
        <a-col>
          <a-button type="primary" @click="handleCreateInPlan" ghost>{{
            $t("common.create")
          }}</a-button>
        </a-col>
      </a-row>

      <div class="inventory-table mt-3">
        <a-table
          :columns="columns"
          :data-source="planList"
          :scroll="{ x: true, y: wrap.contentHeight - 170 }"
          :pagination="false"
          size="small"
          :loading="planListLoading"
          class="product-table"
        >
          <template #planNameAndNo>
            <div>{{ $t("warehouse.plan_name") }}</div>
            <div>{{ $t("warehouse.out_warehouse_plan_no") }}</div>
          </template>

          <template #outWarehouse>
            <span>
              {{ $t("warehouse.out_warehouse") }}
            </span>
          </template>

          <template #outWarehouseType>
            <span>
              {{ $t("warehouse.out_warehouse_type") }}
            </span>
          </template>
          <template #plannedQuantity>
            <span>
              {{ $t("warehouse.planned_quantity") }}
            </span>
          </template>
          <template #plannedBoxQuantity>
            <span>
              {{ $t("warehouse.planned_box_quantity") }}
            </span>
          </template>
          <template #productQuantity>
            <span>
              {{ $t("warehouse.product_quantity") }}
            </span>
          </template>

          <template #outPlanStatus>
            <span>
              {{ $t("warehouse.status") }}
            </span>
          </template>
          <template #transportationDeliveryWay>
            <span>
              {{ $t("warehouse.transportation_delivery_way") }}
            </span>
          </template>
          <template #transportationDeliveryStatus>
            <span>
              {{ $t("warehouse.transportation_delivery_status") }}
            </span>
          </template>
          <template #dateTimeTitle>
            <div>
              {{ $t("warehouse.creation_time") }}
            </div>
            <div>
              {{ $t("warehouse.update_time") }}
            </div>
          </template>
          <template #subsequentStepsTitle>
            <span>
              {{ $t("warehouse.subsequent_steps") }}
            </span>
          </template>

          <template #planNameAndNoCustom="{ record }">
            <div>{{ record.planName }}</div>
            <CPlanNumberItem :no="record.planNo"></CPlanNumberItem>
          </template>

          <template #toRelationPlanNo="{ record }">
            <CPlanNumberItem :no="record.toRelationPlanNo" />
          </template>

          <template #outWarehouseTypeCustom="{ record }">
            <span>
              {{
                $t($enumLangkey("outWarehouseType", record.outWarehouseType))
              }}
            </span>
          </template>

          <template #planStatusCustom="{ record }">
            {{ $t($enumLangkey("outPlanStatus", record.outPlanStatus)) }}
          </template>

          <template #transportationDeliveryWayCustom="{ record }">
            {{
              $t(
                $enumLangkey(
                  "transportationDeliveryWay",
                  record.transportationDeliveryWay
                )
              ) || "-"
            }}
          </template>

          <template #transportationDeliveryStatusCustom="{ record }">
            {{
              $t(
                $enumLangkey(
                  "deliveryStatus",
                  record.transportationDeliveryStatus
                )
              ) || "-"
            }}
          </template>

          <template #dateTimeCustom="{ record }">
            <div>{{ $filters.utcToCurrentTime(record.creationTime) }}</div>
            <div>{{ $filters.utcToCurrentTime(record.updateTime) }}</div>
          </template>

          <template #operateCustom="{ record }">
            <a-dropdown>
              <a-button>
                {{ $t("common.operation") }}<DownOutlined />
              </a-button>
              <template #overlay>
                <a-menu @click="handleClickOperateMenu">
                  <template
                    v-if="record.outPlanStatus == outPlanStatus.inThePlan"
                  >
                    <a-menu-item key="1" :record="record">
                      {{ $t("warehouse.cancel_out_warehouse") }}
                    </a-menu-item>
                    <a-menu-item key="2" :record="record">
                      {{ $t("warehouse.dispose_out_warehouse_plan") }}
                    </a-menu-item>
                  </template>
                  <a-menu-item key="3" :record="record" v-else>
                    {{ $t("warehouse.plan_details") }}
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </template>
        </a-table>
      </div>
    </template>
    <template #contentFooter>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col>
          <CPager @handlePage="handlePage" :page-data="pageData"></CPager>
        </a-col>
      </a-row>
    </template>
  </Content>
</template>

<script>
import { reactive, toRefs, onActivated, ref } from "vue";
import {
  Row,
  Col,
  Table,
  Select,
  Button,
  Form,
  Space,
  Popconfirm,
  message,
  Input,
  DatePicker,
  Menu,
  Dropdown,
  Modal,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import CPlanNumberItem from "../../components/CPlanNumberItem.vue";
import {
  getOutPlans,
  cancelOutPlan,
  exportOutWarehousePlan,
} from "../../../api/modules/storage/index";
import {
  outPlanStatus,
  warehouseType as warehouseTypeEnum,
  outWarehouseType as outWarehouseTypeEnum,
  deliveryStatus as deliveryStatusEnum,
  transportationDeliveryWay as transportationDeliveryWayEnum,
} from "../../../enum/enum.json";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n/index";
import { getWarehouses } from "../../../api/modules/common/index";
import { currentTimeToUtc, dateString } from "@/utils/general";
import { downloadFile } from "@/utils/downloader";
import SearchProduct from "../../components/SearchProduct.vue";

export default {
  name: "storage_out_plan",
  components: {
    SearchProduct,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    APopconfirm: Popconfirm,
    AInput: Input,
    ARangePicker: DatePicker.RangePicker,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    Content,
    CPager,
    CPlanNumberItem,
  },
  setup() {
    const router = useRouter();
    const vueI18n = useI18n({ useScope: "global" });

    const refPage = ref();
    const refSearchProduct = ref();

    const dropdownList = reactive({
      outWarehouse: [],
    });

    const state = reactive({
      planListLoading: false,
      outWarehouseLoading: false,
      exportLoading: false,
    });

    const data = reactive({
      plan: null,
      warehouseId: null,
      outWarehouseType: null,
      outWarehouseStatus: null,
      transDeliverType: null,
      transDeliverStatus: null,
      dataRange: null,
      box: null,
      planList: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const getSearchData = () => {
      let beginTime, endTime;
      if (data.dataRange && data.dataRange.length > 0) {
        beginTime = currentTimeToUtc(
          data.dataRange[0]?.format("YYYY-MM-DD 00:00:00")
        );
        endTime = currentTimeToUtc(
          data.dataRange[1]?.format("YYYY-MM-DD 00:00:00")
        );
      }

      let { pageData, planList, dataRange, ...searchState } = data;

      return Object.assign({}, searchState, {
        warehouseType: warehouseTypeEnum.storageWarehouse,
        beginCreationTime: beginTime,
        endCreationTime: endTime,
        productId: refSearchProduct.value.selectedProductId,
      });
    };

    const handleDownloadExcel = () => {
      state.exportLoading = true;
      let url = exportOutWarehousePlan();
      const data = Object.assign({}, getSearchData());
      downloadFile(url, `ws_outbound_plan_${dateString()}.csv`, "POST", data)
        .then(() => {
          message.success(vueI18n.t("common.succeed"));
        })
        .catch(() => {})
        .finally(() => {
          state.exportLoading = false;
        });
    };

    const handleChangeStatus = () => {
      data.pageData.skipCount = 0;
      data.pageData.currentIndex = 1;
      funcSearchPlans();
    };

    const handlePage = async (pageData) => {
      data.pageData.skipCount = pageData.skipCount;
      data.pageData.maxResultCount = pageData.maxResultCount;
      funcSearchPlans(pageData);
    };

    const funcSearchPlans = async (pageData) => {
      const searchData = Object.assign({}, getSearchData(), {
        ...data.pageData,
      });

      state.planListLoading = true;
      getOutPlans(searchData)
        .then((res) => {
          if (res.result) {
            let { items, totalCount } = res.result;
            data.planList = items.map((x) => {
              return {
                key: x.id,
                id: x.id,
                planName: x.planName,
                planNo: x.planNo,
                toRelationPlanNo: x.toRelationPlanNo,
                outWarehouse: x.warehouseNo,
                outWarehouseType: x.outWarehouseType,
                targetWarehouse: x.targetWarehouse,
                plannedQuantity: x.total.plan.totalWarehouseCount,
                plannedBoxQuantity: x.total.plan.totalContainerBoxCount,
                productQuantity: x.total.plan.totalCount,
                outPlanStatus: x.outWarehouseStatus,
                transportationDeliveryWay: x.transDeliverType,
                transportationDeliveryStatus: x.transDeliverStatus,
                creationTime: x.creationTime,
                updateTime: x.lastModificationTime,
                cancelLoading: false,
              };
            });
            data.pageData.totalCount = parseInt(totalCount);
          } else {
            data.pageData.totalCount = 0;
          }
        })
        .finally(() => {
          state.planListLoading = false;
        });
    };

    const handleOperation = async (record) => {
      router.push({ path: "/storage/outplandetails" });
    };

    const handleCreateInPlan = async () => {
      //跳转路由
      router.push({ path: "/storage/selectoutway" });
    };

    const funcGetOutWarehouse = () => {
      let searchData = {
        warehouseType: warehouseTypeEnum.storageWarehouse,
        isActive: true,
      };
      getWarehouses(searchData).then((res) => {
        dropdownList.outWarehouse = res.result;
      });
    };

    const handleClickOperateMenu = (e) => {
      let { key, item } = e;
      let { record } = item;

      switch (key) {
        case "1":
          Modal.confirm({
            title: vueI18n.t("common.operation"),
            content: vueI18n.t("common.are_you_sure_cancel"),
            okText: vueI18n.t("common.confirm"),
            cancelText: vueI18n.t("common.cancel"),
            onOk: () => {
              return cancelOutPlan(record.id).then(({ result }) => {
                message.success(vueI18n.t("common.succeed"));
                handleChangeStatus();
              });
            },
          });
          break;
        case "2":
          router.push(`/storage/confirmoutplan/${record.id}`);
          break;
        case "3":
          router.push(`/storage/outplandetails/${record.id}`);
          break;
        default:
          break;
      }
    };

    onActivated(async () => {
      funcSearchPlans();
      if (!dropdownList.outWarehouse || dropdownList.outWarehouse.length == 0) {
        funcGetOutWarehouse();
      }
    });

    const columns = [
      {
        dataIndex: "planNameAndNo",
        slots: {
          title: "planNameAndNo",
          customRender: "planNameAndNoCustom",
        },
        fixed: "left",
        width: 200,
      },
      {
        title: () => vueI18n.t("warehouse.target_related_doc_no"),
        width: 150,
        slots: {
          customRender: "toRelationPlanNo",
        },
      },
      {
        dataIndex: "outWarehouse",
        slots: {
          title: "outWarehouse",
        },
        width: 120,
      },
      {
        dataIndex: "outWarehouseType",
        slots: {
          title: "outWarehouseType",
          customRender: "outWarehouseTypeCustom",
        },
        width: 120,
      },
      {
        dataIndex: "plannedQuantity",
        slots: {
          title: "plannedQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "plannedBoxQuantity",
        slots: {
          title: "plannedBoxQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "productQuantity",
        slots: {
          title: "productQuantity",
        },
        width: 100,
      },
      {
        dataIndex: "outPlanStatus",
        slots: {
          title: "outPlanStatus",
          customRender: "planStatusCustom",
        },
        width: 100,
      },
      {
        dataIndex: "transportationDeliveryWay",
        slots: {
          title: "transportationDeliveryWay",
          customRender: "transportationDeliveryWayCustom",
        },
        width: 150,
      },
      {
        dataIndex: "transportationDeliveryStatus",
        slots: {
          title: "transportationDeliveryStatus",
          customRender: "transportationDeliveryStatusCustom",
        },
        width: 150,
      },
      {
        width: 150,
        slots: {
          title: "dateTimeTitle",
          customRender: "dateTimeCustom",
        },
      },
      {
        dataIndex: "subsequentSteps",
        slots: {
          title: "subsequentStepsTitle",
          customRender: "operateCustom",
        },
        fixed: "right",
        width: 130,
      },
    ];

    return {
      columns,
      ...toRefs(state),
      ...toRefs(data),
      outPlanStatus,
      outWarehouseTypeEnum,
      deliveryStatusEnum,
      transportationDeliveryWayEnum,
      refPage,
      dropdownList,

      handlePage,
      handleOperation,
      handleCreateInPlan,
      funcSearchPlans,
      handleChangeStatus,
      handleClickOperateMenu,
      handleDownloadExcel,

      refSearchProduct,
    };
  },
};
</script>

<style lang="less"></style>
